import React, {useEffect, useState} from "react";


const Remote = (props) => {
    const [remote, setRemote] = useState([]);
    useEffect(() => {
        setRemote(props.remote);
    }, []);
    return (
        <div>
            {Object.keys(remote).map(k => {
                return (
                    <p>{k} : {remote[k]}</p>
                )
            })}
        </div>
    )
};

export default Remote;