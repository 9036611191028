import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import "bootstrap/dist/css/bootstrap.min.css";

import UserService from "../../services/user.service";
import HSBar from "react-horizontal-stacked-bar-chart";


const AllTestRuns = () => {
        const history = useHistory();

        const [alltestruns, setAllTestRuns] = useState([]);

        useEffect(() => {
            UserService.getRecentTestRuns().then(
                (response) => {
                    setAllTestRuns(response.data);
                }
            ).catch(err => console.error(err));
        }, [])

        const columns = [
            {
                dataField: 'id',
                text: 'Id',
                sort: true,
                filter: textFilter(),
                headerStyle: (column, colIndex) => {
                    return {width: '40rem'};
                }
            }, {
                dataField: 'name',
                sort: true,
                filter: textFilter(),
                text: 'Testrun name',
                headerStyle: (column, colIndex) => {
                    return {width: '600rem'};
                }
            }, {
                dataField: 'platform',
                sort: true,
                filter: textFilter(),
                text: 'Platform',
                headerStyle: (column, colIndex) => {
                    return {width: '150rem'};
                }
            }, {
                dataField: 'statuses',
                sort: true,
                text: 'Statuses',
                headerStyle: (column, colIndex) => {
                    return {width: '150rem'};
                },
                formatter: (cellContent, row) => {
                    return (
                                                <HSBar
                                                    showTextIn
                                                    id="hsbarStatus"
                                                    fontColor="black"
                                                    data={[
                                                        {
                                                            value: row.statuses.passed,
                                                            description: row.statuses.passed,
                                                            color: "#34eb3a",
                                                        },
                                                        {
                                                            value: row.statuses.failed,
                                                            description: row.statuses.failed,
                                                            color: "#fc0000"
                                                        },
                                                    ]}
                                                />
                    )
                }
            }, {
                dataField: 'client_ver',
                sort: true,
                filter: textFilter(),
                text: 'Client Version',
                headerStyle: (column, colIndex) => {
                    return {width: '200rem'};
                }
            }, {
                dataField: 'published',
                sort: true,
                filter: textFilter(),
                text: 'Date',
                headerStyle: (column, colIndex) => {
                    return {width: '200rem'};
                }, formatter: (cellContent, row) => {
                    return (
                        <p>{row.published.slice(0, 19)}</p>
                    )
                }
            }
        ];

        const nextPath = (path) => {
            history.push(path);
        }

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                return nextPath(`/testrun/${row.slug}`)
            }
        }

        return (

            <React.Fragment>
                <div container className="p-3 mt-2">
                    <BootstrapTable
                        hover
                        keyField='id'
                        data={alltestruns}
                        columns={columns}
                        filter={filterFactory()}
                        rowEvents={rowEvents}
                        striped
                    />
                </div>
            </React.Fragment>
        )
    }
;


export default AllTestRuns;