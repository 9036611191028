import React, {useEffect, useState} from "react";

import Table from "react-bootstrap/lib/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import Remote from "./remote";

const TetClient = (props) => {

    const [testclient, setTestclient] = useState([]);
    const [runnels, setRunnels] = useState([]);
    const [remotes, setRemotes] = useState([]);
    useEffect(() => {
        setTestclient(props.testclient);
        setRunnels(props.testclient.runnels);
        setRemotes(props.testclient.remotes);
    }, []);

    return (
        <React.Fragment>
            <div className="table-responsive col-md-6">
                <Table striped bordered className="table table-dark table-hover table-striped">
                    <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Client name</td>
                        <td>{testclient.name}</td>
                    </tr>
                    <tr>
                        <td>Client type</td>
                        <td>{testclient.type}</td>
                    </tr>
                    <tr>
                        <td>Router name</td>
                        <td>{testclient.router_name}</td>
                    </tr>
                    <tr>
                        <td>Video codec</td>
                        <td>{testclient.video_codec}</td>
                    </tr>
                    <tr>
                        <td>Audio codec</td>
                        <td>{testclient.audio_codec}</td>
                    </tr>
                    <tr>
                        <td>Send fps</td>
                        <td>{testclient.fps_send}</td>
                    </tr>
                    <tr>
                        <td>Send resolution</td>
                        <td>{testclient.resolution_send}</td>
                    </tr>
                    <tr>
                        <td>CodecFirs</td>
                        <td>{testclient.codec_firs}</td>
                    </tr>
                    <tr>
                        <td>CodecNACKS</td>
                        <td>{testclient.codec_nacks}</td>
                    </tr>
                    <tr>
                        <td>CodecPLI</td>
                        <td>{testclient.codec_pli}</td>
                    </tr>
                    <tr>
                        <td>CodecIFrames</td>
                        <td>{testclient.codec_iframes}</td>
                    </tr>
                    <tr>
                        <td>QualityLimitationReason</td>
                        <td>{testclient.quality_limitation_reason}</td>
                    </tr>
                    <tr>
                        <td>receiveBitRateAvailable</td>
                        <td>{testclient.received_available}</td>
                    </tr>
                    <tr>
                        <td>receiveBitRateTotal</td>
                        <td>{testclient.received_total}</td>
                    </tr>
                    <tr>
                        <td>sendBitRateAvailable</td>
                        <td>{testclient.send_available}</td>
                    </tr>
                    <tr>
                        <td>sendBitRateTotal</td>
                        <td>{testclient.send_total}</td>
                    </tr>
                    <tr>
                        <td>runnels</td>
                        <td>{Object.keys(runnels).map(k => {
                           return (
                                <p>{k} : <Remote remote={runnels[k]}/></p>
                            )
                        })}</td>
                    </tr>
                    <tr>
                        <td>remotes</td>
                        <td>{Object.keys(remotes).map(k => {
                            return (
                                <p>{k} : <Remote remote={remotes[k]}/></p>
                            )
                        })}</td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
};

export default TetClient;