import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import "bootstrap/dist/css/bootstrap.min.css";

import "./styles.css";
import UserService from "../../services/user.service";
import TetClients from "./testClients";


const SingleTestRun = () => {
    const {slug} = useParams();

    const [testrun, setTestRun] = useState([]);
    const [testcases, setTestCases] = useState([]);
    const [testclients, setTestClients] = useState([]);

    useEffect(() => {
        UserService.getSingleTestRun(slug).then(
            (response) => {
                setTestRun(response.data);
                setTestCases(response.data.testcases);
                setTestClients(response.data.testcases.testclients)
            }
        ).catch(err => console.error(err));
    }, [])

    const columns = [
        {
            dataField: 'name',
            text: 'Testcase name',
            sort: true,
            filter: textFilter(),
        }, {
            dataField: 'status',
            isDummyField: true,
            sort: true,
            filter: textFilter(),
            text: 'Status',
            formatter: (cellContent, row) => {
                if (row.status === "passed") {
                    return (
                        <h5>
                            <span className="badge bg-success"> Passed</span>
                        </h5>
                    );
                }
                return (
                    <h5>
                        <span className="badge bg-danger"> Failed </span><span className="p-2">{row.fail_reason}</span>
                    </h5>
                );
            }
        }
    ];

    const expandRow = {
        onlyOneExpanding: true,
        renderer: row => (
            <TetClients testclients={row.testclients}/>
        )
    };

    return (

        <React.Fragment>
            <div container className="p-3 mt-2">
                <h3 className="shadow-sm p-3 mb-3 bg-white">
                    {testrun.name} {testrun.client_ver} {testrun.platform} {testrun.type}
                </h3>
                <div>
                    <div className="shadow p-3 mb-3 bg-white">
                        <p>
                            Portal: {testrun.portal}
                        </p>
                        <p>
                            Portal Version: {testrun.portal_ver}
                        </p>
                        <p>
                            Testrun date: {testrun.published}
                        </p>
                    </div>
                    <BootstrapTable
                        keyField='name'
                        data={testcases}
                        columns={columns}
                        expandRow={expandRow}
                        filter={filterFactory()}
                        striped
                    />

                </div>
            </div>
        </React.Fragment>
    )
};


export default SingleTestRun;