import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import PropTypes from "prop-types";

import HSBar from "react-horizontal-stacked-bar-chart";

import "./styles.css";
import "../../App.css";
import UserService from "../../services/user.service";


const useStyles = makeStyles((theme) => ({
    link: {
        margin: theme.spacing(1, 1.5),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[700],
    },
    testrunTitle: {
        fontSize: '16px',
        textAlign: 'left',
    },
    testrunText: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'baseline',
        fontSize: '12px',
        textAlign: 'left',
        marginBottom: theme.spacing(1),
    },
}));


const TestRuns = () => {
    const [testruns, setTestRuns] = useState("");

    useEffect(() => {
        UserService.getRecentTestRuns().then(
            (response) => {
                setTestRuns(response.data.splice(0, 10));
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();

                setTestRuns(_content);
            }
        );
    }, [])
    const classes = useStyles();
    if (!testruns || testruns.length === 0) return <p data-test="testrunErrorMsg">Can not find any testruns, sorry</p>;
    return (
        <div className="App">
            <Typography
                variant="h3"
            >Latest TestRuns</Typography>
            <React.Fragment>
                <Container maxWidth="md" component="main">
                    <Grid container spacing={5} alignItems="flex-end">
                        {testruns.map((testrun) => {
                            return (
                                <Grid item key={testrun.published} xs={12} md={4}>
                                    <Link
                                        href="#"
                                        style={{textDecoration: 'none'}}
                                        color="textPrimary"
                                        underline="none"
                                        to={'/testrun/' + testrun.slug}
                                        className={classes.link}
                                    >
                                        <Card className={classes.card} data-test="testrunComponent">
                                            <CardContent className={classes.cardContent}>

                                                <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    component="h2"
                                                    className={classes.testrunTitle}
                                                >
                                                    <b>{testrun.name}</b>
                                                </Typography>

                                                <HSBar
                                                    showTextIn
                                                    id="hsbarStatus"
                                                    fontColor="black"
                                                    data={[
                                                        {
                                                            value: testrun.statuses.passed,
                                                            description: testrun.statuses.passed,
                                                            color: "#34eb3a",
                                                        },
                                                        {
                                                            value: testrun.statuses.failed,
                                                            description: testrun.statuses.failed,
                                                            color: "#fc0000"
                                                        },
                                                    ]}
                                                />

                                                <div className={classes.testrunText}>
                                                    <Typography variant="p" color="textPrimary">
                                                        Client Version: {testrun.client_ver}
                                                    </Typography>
                                                </div>
                                                <div className={classes.testrunText}>
                                                    <Typography variant="p" color="textPrimary">
                                                        Type: {testrun.type}
                                                    </Typography>
                                                </div>
                                                <div className={classes.testrunText}>
                                                    <Typography variant="p" color="textPrimary">
                                                        Platform: {testrun.platform}
                                                    </Typography>
                                                </div>
                                                <div className={classes.testrunText}>
                                                    <Typography variant="p" color="textPrimary">
                                                        Portal: {testrun.portal}
                                                    </Typography>
                                                </div>
                                                <div className={classes.testrunText}>
                                                    <Typography variant="p" color="textPrimary">
                                                        Portal Version: {testrun.portal_ver}
                                                    </Typography>
                                                </div>
                                                <div className={classes.testrunText}>
                                                    <Typography variant="p" color="textSecondary">
                                                        Date: {testrun.published.slice(0, 19)}
                                                    </Typography>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Link>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Container>
            </React.Fragment>
        </div>
    )
};

TestRuns.propTypes = {
    testruns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            client_ver: PropTypes.string,
            platform: PropTypes.string,
            portal: PropTypes.string,
            portal_ver: PropTypes.string,
            published: PropTypes.string,
            testcases: PropTypes.array
        }))
};

export default TestRuns;