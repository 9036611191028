import React from "react";
import TetClient from "./testClient";



const TetClients = (props) => {

    return (
        <React.Fragment>
            <div className="row row-cols-2">
            {props.testclients.map(testclient => {
                return <TetClient testclient={testclient}/>
            })};
            </div>
        </React.Fragment>
    )
};

export default TetClients;