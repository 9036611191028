import React from "react";
import { Route, HashRouter as Router, Switch } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/login/login";
import Register from "./components/register/register";

import {history} from "./helpers/history";
import TestRuns from "./components/testruns/testRuns";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import SingleTestRun from "./components/singleTestrun/singleTestrun";
import AllTestRuns from "./components/alltestruns/alltestruns";


const App = () => {

    return (
        <Router history={history}>
            <React.StrictMode>
                <Header/>
                <Switch>
                    <Route exact path="/" component={TestRuns}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/register" component={Register}/>
                    <Route path="/testrun/:slug" component={SingleTestRun} />
                    <Route path="/alltestruns" component={AllTestRuns} />
                </Switch>
                <Footer/>
            </React.StrictMode>
        </Router>
    );
};

export default App;