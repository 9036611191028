import axios from "axios";


const API_URL = process.env.REACT_APP_API_URL;

const register = (username, email, password) => {
    return axios.post(API_URL + "user/register/", {
        username,
        email,
        password,
    });
};

const login = (username, password) => {
    return axios
        .post(API_URL + "token/", {
            username,
            password,
        })
        .then((response) => {
            if (response.data.access) {
                localStorage.setItem("access_token", JSON.stringify(response.data.access));
                localStorage.setItem("refresh_token", JSON.stringify(response.data.refresh_token));
            }

            return response.data;
        });
};

const logout = () => {
    const response = axios.post('user/logout/blacklist/', {
        refresh_token: localStorage.getItem('refresh_token'),
    });
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
};

export default {
    register,
    login,
    logout,
};