import React, {useEffect} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import {history} from "../../helpers/history";
import {clearMessage} from "../../actions/message";
import {logout} from "../../actions/auth";


const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    link: {
        margin: theme.spacing(2, 1.5),
    },
    toolbarTitle: {
        textDecoration: 'none',
        flexGrow: 1,
    },
    headerPadding: {
        paddingLeft: 8,
        paddingRight: 8
    }
}));

function Header() {
    const classes = useStyles();
    const {user: currentUser} = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        history.listen((location) => {
            dispatch(clearMessage()); // clear message when changing location
        });
    }, [dispatch]);

    const logOut = () => {
        dispatch(logout());
    };
    return (
        <React.Fragment>
            <CssBaseline/>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                className={classes.appBar}
                data-test="headerToolbar"
            >
                <Toolbar className={classes.toolbar}>
                    <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.toolbarTitle}
                        data-test="headerLogoTitle"
                    >
                        <Link
                            style={{textDecoration: 'none', flexGrow: 1, color: 'primary'}}
                            to="/"
                            underline="none"
                            color="textPrimary"
                        >
                            StatsVisualizer
                        </Link>
                        <Link
                            style={{textDecoration: 'none', flexGrow: 1, color: 'secondary'}}
                            to="/alltestruns"
                            underline="none"
                            color="textPrimary"
                            className={classes.headerPadding}
                        >
                            AllTestruns
                        </Link>
                    </Typography>
                    {currentUser ? (
                        <Button
                            href="#"
                            color="primary"
                            variant="outlined"
                            className={classes.link}
                            component={NavLink}
                            to="/"
                            onClick={logOut}
                        >
                            Logout
                        </Button>
                    ) : (
                        <nav>
                            <Button
                                color="primary"
                                variant="outlined"
                                href="#"
                                className={classes.link}
                                component={NavLink}
                                to={"/register"}
                            >
                                Register
                            </Button>

                            <Button
                                href="#"
                                color="primary"
                                variant="outlined"
                                className={classes.link}
                                component={NavLink}
                                to={"/login"}
                            >
                                Login
                            </Button>
                        </nav>
                    )}
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

export default Header;
