import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getRecentTestRuns = () => {
  return axios.get(API_URL);
};

const getSingleTestRun = (slug) => {
  return axios.get(API_URL + slug);
};


export default {
  getRecentTestRuns,
  getSingleTestRun,
};
